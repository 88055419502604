import { Grid } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import PieChartIcon from "@material-ui/icons/PieChart";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ProductsManagementFiltersContext } from "../../../contexts/ProductsManagementFiltersProvider";
import { formatStoresToId, prettyPercentage } from "../../../helpers";
import { useSelectedStores } from "../../../hooks/stores";
import { getAbcAnalysis, getCurvesTotals } from "../../../services/abc";
import { filters as ordersFilters, useOrders } from "../../../services/orders";
import {
  getProductsWithoutTurn,
  useLowTurnProducts
} from "../../../services/product-management";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderTitle,
  LoadingDialog
} from "../../../_metronic/_partials/controls";
import BrandsAbcAnalysis from "../BrandsAbcAnalysis";
import FinancialResults from "../FinancialResults";
import LowTurn from "../LowTurn";
import NeedToBuy from "../NeedToBuy";
import NeedToBuyBrand from "../NeedToBuy/NeedToBuyBrand";
import WithoutTurn from "../WithoutTurn";
import CurveComparison from "./CurveComparison";
import CurveHeader from "./CurveHeader";
import CurveResume from "./CurveResume";
import CurveTab from "./CurveTab";
import HintsAndTipsCard from "./HintsAndTipsCard";
import "./styles.scss";
import {MarkupHelper} from "../../../utils/calcs/MarkupHelper";
import {GrossMarginHelper} from "../../../utils/calcs/GrossMarginHelper";
function reduceFinancialGroups(groups, property) {
  return groups.reduce((prev, curr) => {
    return prev + parseFloat(curr[property]);
  }, 0);
}

function reduceCurves(curves) {
  return Object.keys(curves).reduce((carry, curve) => {
    return carry + curves[curve];
  }, 0);
}

const curveSubtitle = curve =>
  `Detalhamento dos Produtos com Necessidade de Reposição – Sugestão de Compra Curva ${curve.toUpperCase()}`;

const curveStyles = {
  a: {
    color: "curve-a",
    title: "Curva A",
    subtitle: curveSubtitle("a"),
    percentage: 50
  },
  b: {
    color: "curve-b",
    title: "Curva B",
    subtitle: curveSubtitle("b"),
    percentage: 20
  },
  c: {
    color: "curve-c",
    title: "Curva C",
    subtitle: curveSubtitle("c"),
    percentage: 30
  },
  without_turn: {
    color: "without-turn",
    title: "Sem Giro",
    subtitle:
      "Aqui você encontra os itens que não venderam nada no período analisado",
    percentage: 0
  },
  low_turn: {
    color: "curve-c",
    title: "Baixo Giro",
    subtitle:
      "Neste grupo de produtos estão todos os itens com Baixo Giro (cauda longa) que compõem seu sortimento",
    percentage: 0
  }
};

const INITIAL_TOTALS_VALUES = {
  totalValueData: {}
};

const timeFilters = {
  4: ordersFilters.time.LAST_FOUR_WEEKS,
  8: ordersFilters.time.LAST_EIGHT_WEEKS,
  12: ordersFilters.time.LAST_TWELVE_WEEKS
};

export default function AbcAnalysis() {
  const { weeksInterval, category, subCategory, segment } = useContext(
    ProductsManagementFiltersContext
  );
  const selectedStores = useSelectedStores();
  const storesId = formatStoresToId(selectedStores);
  const params = {
    ...timeFilters[weeksInterval],
    category,
    subcategory: subCategory,
    segment
  };

  if (selectedStores.length) {
    params.stores = storesId.join(",");
  }

  const { data: financialResultsData } = useOrders(params);
  const analysisType = "totalValueData";
  const [loading, setLoading] = useState(false);
  const [withoutTurnData, setWithoutTurnData] = useState(null);
  const [dataType, setDataType] = useState(undefined);
  const [curvesFinancialResults, setCurvesFinancialResults] = useState(null);
  const [
    currentBuyNecessityCurveData,
    setCurrentBuyNecessityCurveData
  ] = useState(null);
  const totalPeriodValue = financialResultsData
    ? reduceFinancialGroups(
        financialResultsData.groups,
        "total_value_without_discounts"
      )
    : 0;
  const totalPeriodCost = financialResultsData
    ? reduceFinancialGroups(financialResultsData.groups, "total_products_cost")
    : 0;
  const totalPeriodGrossProfit = financialResultsData
    ? financialResultsData.gross_profit
    : 0;
  const totalPeriodSoldItems =
    financialResultsData && withoutTurnData
      ? parseFloat(financialResultsData.total_unique_items) +
        parseFloat(withoutTurnData?.items || 0)
      : 0;

  const [stockParts, setStockParts] = useState({ ...INITIAL_TOTALS_VALUES });
  const [totalSoldQuantity, setTotalSoldQuantity] = useState({
    ...INITIAL_TOTALS_VALUES
  });
  const [totalGrossProfit, setTotalGrossProfit] = useState({
    ...INITIAL_TOTALS_VALUES
  });
  const [totalSoldValue, setTotalSoldValue] = useState({
    ...INITIAL_TOTALS_VALUES
  });
  const [totalStockQuantity, setTotalStockQuantity] = useState({
    ...INITIAL_TOTALS_VALUES
  });
  const [totalStockValue, setTotalStockValue] = useState({
    ...INITIAL_TOTALS_VALUES
  });

  const [cachedPages, setCachedPages] = useState({
    totalValueData: { a: {}, b: {}, c: {} }
  });

  const brandsRef = useRef();

  const handleChangeCurve = curve => {
    if (curve !== dataType) {
      setDataType(curve);
    }

    if (brandsRef.current) {
      brandsRef.current.changeCurve(curve);
    }
  };

  const lowTurnParams = {
    weeks: weeksInterval,
    category,
    subcategory: subCategory,
    segment
  };

  if (selectedStores.length) {
    lowTurnParams.stores = storesId.join(",");
  }

  const { data: lowTurnData } = useLowTurnProducts(lowTurnParams);

  const fetchWithoutTurnData = async () => {
    let stores = null;

    if (selectedStores.length) {
      stores = storesId.join(",");
    }

    try {
      const { data } = await getProductsWithoutTurn(
        1,
        stores,
        weeksInterval,
        null,
        category,
        subCategory,
        segment
      );

      setWithoutTurnData({
        items: data.total,
        stock_percentage: parseFloat(data.stock_percentage),
        total_stock_value: parseFloat(data.in_stock),
        total_stock_quantity: parseFloat(data.stock_quantity)
      });
    } catch (e) {
      console.log(e);
    }
  };

  const fetchData = async page => {
    setLoading(true);

    const params = {
      weeks: weeksInterval,
      category,
      subcategory: subCategory,
      segment
    };

    if (selectedStores.length) {
      params.stores = formatStoresToId(selectedStores).join(",");
    }

    try {
      const res = await getAbcAnalysis(page, params);

      const newCache = { ...cachedPages };

      newCache.totalValueData.a[page] = res.data.totalValueData.data.a;
      newCache.totalValueData.b[page] = res.data.totalValueData.data.b;
      newCache.totalValueData.c[page] = res.data.totalValueData.data.c;

      setCachedPages(newCache);

      const reduceData = property => ({
        totalValueData: res.data.totalValueData[property]
      });

      const typeStockParts = reduceData("stock_parts");
      const typeSoldQuantity = reduceData("sold_quantity");
      const typeGrossProfit = reduceData("gross_profit");
      const typeSoldValue = reduceData("total_value_without_discounts");
      const typeStockQuantity = reduceData("stock_quantity");
      const typeStockValue = reduceData("stock_value");

      setStockParts(typeStockParts);
      setTotalSoldQuantity(typeSoldQuantity);
      setTotalGrossProfit(typeGrossProfit);
      setTotalSoldValue(typeSoldValue);
      setTotalStockQuantity(typeStockQuantity);
      setTotalStockValue(typeStockValue);
    } catch (e) {
      console.log(e);
    }

    setLoading(false);
  };

  const fetchTotals = async () => {
    try {
      const params = {
        weeks: weeksInterval,
        category,
        subcategory: subCategory,
        segment,
        only_with_buy_necessity: 0
      };

      if (selectedStores.length) {
        params.stores = formatStoresToId(selectedStores).join(",");
      }

      const { data } = await getCurvesTotals(params);

      setCurvesFinancialResults(data);
    } catch (e) {
      console.log(e);
    }
  };

  const curveFinancialResults = c => {
    return {
      total_items: curvesFinancialResults?.sold_quantity[c] || 0,
      total_unique_items: curvesFinancialResults?.totals[c] || 0,
      gross_profit: curvesFinancialResults?.gross_profit[c] || 0,
      total_value: curvesFinancialResults?.total_value[c] || 0,
      total_value_without_discounts:
        curvesFinancialResults?.total_value_without_discounts[c] || 0,
      total_cost: curvesFinancialResults?.cmv[c] || 0,
      total_in_stock_quantity: curvesFinancialResults?.stock_quantity[c] || 0,
      total_in_stock: curvesFinancialResults?.stock_value[c] || 0,
      gross_margin: GrossMarginHelper.calculate(
        curvesFinancialResults?.total_value_without_discounts[c], 
        curvesFinancialResults?.gross_profit[c]),
      mark_up: MarkupHelper.calculate(
        curvesFinancialResults?.total_value_without_discounts[c], 
        curvesFinancialResults?.cmv[c]) 
    };
  };

  useEffect(() => {
    fetchData(1);
    fetchWithoutTurnData();
    fetchTotals();
  }, [selectedStores, weeksInterval, category, subCategory, segment]);

  const backHandler = e => {
    e.preventDefault();

    if (["without_turn", "low_turn"].includes(dataType)) {
      setCurrentBuyNecessityCurveData(undefined);
      setDataType(undefined);

      return;
    }

    if (currentBuyNecessityCurveData) {
      setCurrentBuyNecessityCurveData(undefined);
      setDataType("buy_necessity");
      return;
    }

    setDataType(undefined);
  };

  const dateFormat = "DD/MM/YY";
  const initialDate = moment()
    .subtract(1, "day")
    .subtract(weeksInterval, "weeks")
    .startOf("day")
    .format(dateFormat);
  const endDate = moment()
    .subtract(1, "day")
    .endOf("day")
    .format(dateFormat);
  const curveTip = (curve, grossMargin, markUp) =>
    `Produtos Curva ${curve} com as informações de participação nas Vendas, no Lucro Bruto R$ - MG Bruta (%) ${grossMargin.toFixed(
      1
    )} - MarkUp ${markUp.toFixed(
      2
    )} e nº de itens únicos vendidos no período analisado. Além da participação no valor do Estoque em ${endDate}.`;

  const backButtonWrapper = (
    <div className={!!currentBuyNecessityCurveData ? "mb-5" : null}>
      <Link
        to={""}
        onClick={backHandler}
        className="text-dark d-flex align-items-center"
      >
        <ArrowBackIosIcon style={{ fontSize: "1.2rem" }} />
        <span>Voltar</span>
      </Link>
    </div>
  );

  useEffect(() => {
    if (dataType) {
      if (dataType === "low_turn") {
        setCurrentBuyNecessityCurveData({
          curve: "low_turn",
          items: lowTurnData?.total || 0,
          totalSoldQuantity: lowTurnData?.sold_quantity || 0,
          stockQuantity: lowTurnData?.stock_quantity || 0,
          totalSoldValue: lowTurnData?.total_value_without_discounts || 0,
          grossProfit: lowTurnData?.gross_profit || 0,
          stockValue: lowTurnData?.stock_value || 0,
          ordersPercentage: totalPeriodValue
            ? parseFloat(lowTurnData?.total_value_without_discounts) /
              totalPeriodValue
            : 0,
          stockPercentage: (lowTurnData?.stock_percentage || 0) / 100,
          cost: lowTurnData?.cost || 0
        });

        return;
      }

      if (dataType === "without_turn") {
        setCurrentBuyNecessityCurveData({
          curve: "without_turn",
          items: withoutTurnData?.items || 0,
          ordersPercentage: 0,
          stockPercentage: (withoutTurnData?.stock_percentage || 0) / 100,
          stockValue: withoutTurnData?.total_stock_value || 0,
          cost: 0,
          totalSoldQuantity: 0,
          totalSoldValue: 0,
          stockQuantity: withoutTurnData?.total_stock_quantity || 0,
          grossProfit: 0
        });

        return;
      }

      if (["a", "b", "c"].includes(currentBuyNecessityCurveData?.curve)) {
        handleChangeCurve(currentBuyNecessityCurveData?.curve);
      }
    }
  }, [
    dataType,
    currentBuyNecessityCurveData?.curve,
    stockParts,
    lowTurnData,
    withoutTurnData,
    weeksInterval,
    category,
    subCategory,
    segment
  ]);

  const handleChangeCurveData = from => {
    if (from === "low_turn") {
      setDataType("low_turn");

      return;
    }

    if (from === "without_turn") {
      setDataType("without_turn");
    }
  };

  const curvesItems = totalSoldQuantity[analysisType];
  const stockTotal = financialResultsData?.total_in_stock || 0;
  return (
    <>
      <LoadingDialog isLoading={loading} text="Carregando..." />

      {!dataType && !currentBuyNecessityCurveData && (
        <>
          <Grid item xs={12}>
            <Card className="card-heavy-border">
              <CardHeader
                title={
                  <>
                    <CardHeaderTitle className="card-header-with-icon">
                      <PieChartIcon />
                      <span>Curva ABC</span>
                    </CardHeaderTitle>
                    <div className="font-size-sm text-muted mt-2">
                      Análise das Vendas e Estoques dos produtos no período de{" "}
                      {weeksInterval} semanas ({initialDate} a {endDate}).
                    </div>
                  </>
                }
              />

              <CardBody>
                <Grid container justify="center">
                  <FinancialResults
                    items={totalPeriodSoldItems}
                    value={totalPeriodValue}
                    grossProfit={totalPeriodGrossProfit}
                    inStock={financialResultsData?.total_in_stock || 0}
                    cost={totalPeriodCost}
                  />

                  <Grid container spacing={4}>
                    <CurveTab
                      curve="A"
                      color="curve-a"
                      items={curveFinancialResults("a").total_unique_items}
                      stock_part={
                        (stockTotal > 0
                          ? curveFinancialResults("a").total_in_stock /
                            stockTotal
                          : 0) * 100
                      }
                      orders={
                        totalPeriodValue
                          ? (parseFloat(
                              curveFinancialResults("a")
                                .total_value_without_discounts || 0
                            ) /
                              totalPeriodValue) *
                            100
                          : 0
                      }
                      grossProfit={
                        totalPeriodGrossProfit
                          ? (parseFloat(
                              curveFinancialResults("a").gross_profit || 0
                            ) /
                              totalPeriodGrossProfit) *
                            100
                          : 0
                      }
                      tip={curveTip(
                        "A",
                        curveFinancialResults("a").gross_margin || 0,
                        curveFinancialResults("a").mark_up || 0
                      )}
                    />

                    <CurveTab
                      curve="B"
                      color="curve-b"
                      items={curveFinancialResults("b").total_unique_items}
                      stock_part={
                        (stockTotal > 0
                          ? curveFinancialResults("b").total_in_stock /
                            stockTotal
                          : 0) * 100
                      }
                      orders={
                        totalPeriodValue
                          ? (parseFloat(
                              curveFinancialResults("b")
                                .total_value_without_discounts || 0
                            ) /
                              totalPeriodValue) *
                            100
                          : 0
                      }
                      grossProfit={
                        totalPeriodGrossProfit
                          ? (parseFloat(
                              curveFinancialResults("b").gross_profit || 0
                            ) /
                              totalPeriodGrossProfit) *
                            100
                          : 0
                      }
                      tip={curveTip(
                        "B",
                        curveFinancialResults("b").gross_margin || 0,
                        curveFinancialResults("b").mark_up || 0
                      )}
                    />

                    <CurveTab
                      curve="C"
                      color="curve-c"
                      items={curveFinancialResults("c").total_unique_items}
                      stock_part={
                        (stockTotal > 0
                          ? curveFinancialResults("c").total_in_stock /
                            stockTotal
                          : 0) * 100
                      }
                      orders={
                        totalPeriodValue
                          ? (parseFloat(
                              curveFinancialResults("c")
                                .total_value_without_discounts || 0
                            ) /
                              totalPeriodValue) *
                            100
                          : 0
                      }
                      grossProfit={
                        totalPeriodGrossProfit
                          ? (parseFloat(
                              curveFinancialResults("c").gross_profit || 0
                            ) /
                              totalPeriodGrossProfit) *
                            100
                          : 0
                      }
                      tip={curveTip(
                        "C",
                        curveFinancialResults("c").gross_margin || 0,
                        curveFinancialResults("c").mark_up || 0
                      )}
                    />

                    <CurveTab
                      curve="Sem Giro"
                      color="without-turn"
                      items={withoutTurnData?.items || 0}
                      stock_part={
                        stockTotal > 0
                          ? (parseFloat(
                              withoutTurnData?.total_stock_value || 0
                            ) /
                              stockTotal) *
                            100
                          : 0
                      }
                      orders={0}
                      grossProfit={0}
                      tip={curveTip("Sem Giro", 0, 0)}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2} className="mt-10" justify="center">
                  <Grid item xs={12} className="text-center h6 mb-2">
                    Dicas e sugestões do PSI para gestão dos produtos.
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    lg={10}
                    container
                    spacing={2}
                    justify="center"
                  >
                    <HintsAndTipsCard
                      text="1ª: Produtos com necessidade de Compra"
                      color="buy-necessity"
                      onClick={() => setDataType("buy_necessity")}
                    />
                    <HintsAndTipsCard
                      text="2ª: Produtos Sem Giro de Vendas"
                      color="without-turn"
                      onClick={() => handleChangeCurveData("without_turn")}
                    />
                    <HintsAndTipsCard
                      text="3ª: Produtos com Giro Lento de Vendas"
                      color="curve-c"
                      onClick={() => handleChangeCurveData("low_turn")}
                    />
                  </Grid>
                </Grid>
              </CardBody>
            </Card>
          </Grid>
        </>
      )}

      {!!currentBuyNecessityCurveData &&
        (!!currentBuyNecessityCurveData.brand ||
          currentBuyNecessityCurveData.brand === null ||
          currentBuyNecessityCurveData.brand === "") && (
          <Grid item xs={12}>
            {backButtonWrapper}
            <NeedToBuyBrand
              brand={currentBuyNecessityCurveData.brand}
              data={currentBuyNecessityCurveData.data}
              currentBuyNecessityCurveData={currentBuyNecessityCurveData}
              financialResultsData={financialResultsData}
              totalPeriodValue={totalPeriodValue}
              totalPeriodCost={totalPeriodCost}
            />
          </Grid>
        )}

      {(!!dataType || !!currentBuyNecessityCurveData) &&
        !currentBuyNecessityCurveData?.brand &&
        currentBuyNecessityCurveData?.brand !== "" &&
        currentBuyNecessityCurveData?.brand !== null && (
          <>
            <Grid item xs={12}>
              {backButtonWrapper}

              {!!currentBuyNecessityCurveData && (
                <Card className="card-heavy-border">
                  <CardHeader
                    containerClassName={`bg-light-${
                      curveStyles[currentBuyNecessityCurveData.curve].color
                    } curve-title`}
                  >
                    <CurveHeader
                      color={
                        curveStyles[currentBuyNecessityCurveData.curve].color
                      }
                      title={
                        curveStyles[currentBuyNecessityCurveData.curve].title
                      }
                      subtitle={`${
                        curveStyles[currentBuyNecessityCurveData.curve].subtitle
                      } – ${weeksInterval} semanas (${initialDate} a ${endDate})`}
                      curve={currentBuyNecessityCurveData.curve}
                      handleChangeCurve={handleChangeCurve}
                      showCurveSelector={["a", "b", "c"].includes(
                        currentBuyNecessityCurveData.curve
                      )}
                      curvesItems={curvesItems}
                    />
                  </CardHeader>
                  <CardBody>
                    {!!currentBuyNecessityCurveData &&
                      !!curvesFinancialResults && (
                        <>
                          <Grid container spacing={4}>
                            <Grid item xs={12} lg={4}>
                              <CurveResume
                                items={currentBuyNecessityCurveData.items}
                                orders={
                                  dataType === "without_turn"
                                    ? 0
                                    : dataType === "low_turn"
                                    ? currentBuyNecessityCurveData.ordersPercentage
                                    : !currentBuyNecessityCurveData.totalTab
                                    ? totalPeriodValue
                                      ? totalSoldValue[analysisType][
                                          currentBuyNecessityCurveData.curve
                                        ] / totalPeriodValue
                                      : 0
                                    : totalPeriodValue
                                    ? parseFloat(
                                        curvesFinancialResults
                                          ?.total_value_without_discounts[
                                          currentBuyNecessityCurveData.curve
                                        ] || 0
                                      ) / totalPeriodValue
                                    : 0
                                }
                                itemsLabelSuffix={
                                  dataType === "without_turn"
                                    ? "sem giro"
                                    : dataType === "low_turn"
                                    ? "com baixo giro"
                                    : !!currentBuyNecessityCurveData.totalTab
                                    ? ""
                                    : "para reposição"
                                }
                                grossProfit={
                                  dataType === "without_turn"
                                    ? 0
                                    : dataType === "low_turn"
                                    ? financialResultsData?.gross_profit
                                      ? lowTurnData?.gross_profit /
                                        financialResultsData?.gross_profit
                                      : 0
                                    : !currentBuyNecessityCurveData.totalTab
                                    ? totalPeriodGrossProfit
                                      ? totalGrossProfit[analysisType][
                                          currentBuyNecessityCurveData.curve
                                        ] / totalPeriodGrossProfit
                                      : 0
                                    : totalPeriodGrossProfit
                                    ? parseFloat(
                                        curvesFinancialResults?.gross_profit[
                                          currentBuyNecessityCurveData.curve
                                        ] || 0
                                      ) / totalPeriodGrossProfit
                                    : 0
                                }
                                stock={
                                  currentBuyNecessityCurveData.stockPercentage
                                }
                                ordersValue={totalPeriodValue}
                                grossProfitValue={
                                  financialResultsData?.gross_profit
                                }
                                tooltip={
                                  dataType === "without_turn"
                                    ? `O${
                                        currentBuyNecessityCurveData.items > 1
                                          ? "s"
                                          : ""
                                      } ${
                                        currentBuyNecessityCurveData.items > 1
                                          ? currentBuyNecessityCurveData.items
                                          : ""
                                      } ite${
                                        currentBuyNecessityCurveData.items > 1
                                          ? "ns"
                                          : "m"
                                      } que não ${
                                        currentBuyNecessityCurveData.items > 1
                                          ? "tiveram"
                                          : "teve"
                                      } venda no período, ${
                                        currentBuyNecessityCurveData.items > 1
                                          ? "estão"
                                          : "está"
                                      } consumindo o caixa do seu negócio e aumentando a necessidade de capital de giro. Importante tentar ações com Marcas/Fornecedores para buscar soluções para estes itens sem giro. Aqui estão eles organizados por Marca e isso auxiliará a identificá-los e começar a tratar essa questão dos Produtos Sem Giro`
                                    : dataType === "low_turn"
                                    ? `Estes ${
                                        currentBuyNecessityCurveData.items
                                      } itens são de baixo giro representaram ${prettyPercentage(
                                        currentBuyNecessityCurveData.stockPercentage
                                      )} do estoque R$ em ${moment()
                                        .subtract(1, "day")
                                        .format(
                                          "DD/MM/YYYY"
                                        )}, ${prettyPercentage(
                                        currentBuyNecessityCurveData.ordersPercentage
                                      )} da Venda R$ e do Lucro Bruto R$ do período analisado.  Importante desenvolver ações para estimular as vendas destes itens. Buscar apoio das Marcas para desenvolver ações de experimentação, pois muitas vezes o consumidor não conhece esses produtos.`
                                    : false
                                }
                              />
                            </Grid>
                            <Grid item xs={12} lg={8}>
                              <Card>
                                <CardBody>
                                  {dataType === "without_turn" && (
                                    <CurveComparison
                                      curveName="Total"
                                      secondRowName="Sem Giro"
                                      currentBuyNecessityCurveData={
                                        currentBuyNecessityCurveData
                                      }
                                      financialResultsData={
                                        financialResultsData
                                      }
                                      totalPeriodValue={totalPeriodValue}
                                      totalPeriodCost={totalPeriodCost}
                                      showTotalSuffix={false}
                                    />
                                  )}

                                  {dataType === "low_turn" && (
                                    <CurveComparison
                                      curveName="Total"
                                      secondRowName="Baixo Giro"
                                      currentBuyNecessityCurveData={
                                        currentBuyNecessityCurveData
                                      }
                                      financialResultsData={
                                        financialResultsData
                                      }
                                      totalPeriodValue={totalPeriodValue}
                                      totalPeriodCost={totalPeriodCost}
                                      showTotalSuffix={false}
                                    />
                                  )}

                                  {!!currentBuyNecessityCurveData.totalTab && (
                                    <CurveComparison
                                      curveName={
                                        curveStyles[
                                          currentBuyNecessityCurveData?.curve
                                        ].title
                                      }
                                      secondRowName={
                                        curveStyles[
                                          currentBuyNecessityCurveData?.curve
                                        ].title
                                      }
                                      currentBuyNecessityCurveData={
                                        currentBuyNecessityCurveData
                                      }
                                      financialResultsData={curveFinancialResults(
                                        currentBuyNecessityCurveData?.curve
                                      )}
                                      totalPeriodValue={
                                        curveFinancialResults(
                                          currentBuyNecessityCurveData?.curve
                                        ).total_value_without_discounts
                                      }
                                      totalPeriodCost={
                                        curveFinancialResults(
                                          currentBuyNecessityCurveData?.curve
                                        ).total_cost
                                      }
                                      showTotalSuffix={false}
                                    />
                                  )}

                                  {!["without_turn", "low_turn"].includes(
                                    dataType
                                  ) &&
                                    !currentBuyNecessityCurveData.totalTab && (
                                      <CurveComparison
                                        curveName={
                                          curveStyles[
                                            currentBuyNecessityCurveData?.curve
                                          ].title
                                        }
                                        currentBuyNecessityCurveData={
                                          currentBuyNecessityCurveData
                                        }
                                        financialResultsData={curveFinancialResults(
                                          currentBuyNecessityCurveData?.curve
                                        )}
                                        totalPeriodValue={
                                          curveFinancialResults(
                                            currentBuyNecessityCurveData?.curve
                                          ).total_value_without_discounts
                                        }
                                        totalPeriodCost={
                                          curveFinancialResults(
                                            currentBuyNecessityCurveData?.curve
                                          ).total_cost
                                        }
                                      />
                                    )}
                                </CardBody>
                              </Card>
                            </Grid>
                          </Grid>

                          {["a", "b", "c"].includes(
                            currentBuyNecessityCurveData?.curve
                          ) && (
                            <NeedToBuy
                              curve={currentBuyNecessityCurveData.curve}
                              setCurrentBuyNecessityCurveData={
                                setCurrentBuyNecessityCurveData
                              }
                              handleChangeCurve={handleChangeCurve}
                              financialResultsData={financialResultsData}
                            />
                          )}
                        </>
                      )}

                    {dataType === "without_turn" &&
                      currentBuyNecessityCurveData?.curve ===
                        "without_turn" && (
                        <WithoutTurn
                          totalStock={
                            reduceCurves(totalStockValue[analysisType]) +
                            parseFloat(withoutTurnData?.total_stock_value || 0)
                          }
                          withoutTurnStock={parseFloat(
                            withoutTurnData?.total_stock_value || 0
                          )}
                        />
                      )}

                    {dataType === "low_turn" && (
                      <LowTurn
                        totalStock={
                          reduceCurves(totalStockValue[analysisType]) +
                          parseFloat(withoutTurnData?.total_stock_value || 0)
                        }
                      />
                    )}
                  </CardBody>
                </Card>
              )}
            </Grid>
          </>
        )}

      <BrandsAbcAnalysis
        ref={brandsRef}
        curvesTotalSoldValue={totalSoldValue[analysisType]}
        curvesTotalGrossProfit={totalGrossProfit[analysisType]}
        curvesTotalSoldQuantity={totalSoldQuantity[analysisType]}
        curvesStockQuantity={totalStockQuantity[analysisType]}
        curvesStockValue={totalStockValue[analysisType]}
        totalSoldValue={totalPeriodValue}
        totalGrossProfit={financialResultsData?.gross_profit || 0}
        stockValue={financialResultsData?.total_in_stock || 0}
        stockQuantity={financialResultsData?.total_in_stock_quantity || 0}
        handleSetCurrentBuyNecessityCurveData={setCurrentBuyNecessityCurveData}
        currentCurve={currentBuyNecessityCurveData?.curve}
        show={
          !currentBuyNecessityCurveData?.curve &&
          !currentBuyNecessityCurveData?.brand &&
          currentBuyNecessityCurveData?.brand !== "" &&
          currentBuyNecessityCurveData?.brand !== null &&
          dataType === "buy_necessity"
        }
      />
    </>
  );
}
