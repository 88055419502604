export class GrossMarginHelper {
    
    static calculate(priceSale, grossProfit) {
      if (!priceSale || !grossProfit || priceSale <= 0) {
        return 0;
      }

      return (grossProfit / priceSale) * 100;
    }
  }
  